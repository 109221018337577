<template>
  <div>
    <br />
    <div class="row">
      <div class="col-5">
        <label for="">Désignation</label>

        <input
          type="text"
          :disabled="true"
          :value="stock.item ? stock.item.fullName : '--'"
          class="form-control"
        />
      </div>

      <div class="col-3">
        <label for="">Catégorie</label>
        <span v-if="stock.item">
          <input
            type="text"
            :disabled="true"
            :value="stock.item ? stock.item.fullName : '--'"
            class="form-control"
          />
        </span>
      </div>

      <div class="col-2">
        <label for="">Chambre froide</label>
        <input
          type="text"
          :disabled="true"
          :value="stock.cold_room ? stock.cold_room.number : '--'"
          class="form-control"
        />
      </div>

      <div class="col-2">
        <label for="">Quantité</label>
        <input
          type="text"
          :disabled="true"
          :value="stock.quantity + ' '"
          class="form-control"
        />
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-9">
        <label for="">Remarque</label>
        <input
          type="text"
          :disabled="true"
          v-model="stock.remark"
          class="form-control"
        />
      </div>

      <div class="col-3">
        <label for="">Raison</label>
        <select name="" id="" v-model="deletedReason" class="form-select">
          <option value="Achat auprès du fournisseur.">
            Achat auprès du fournisseur.
          </option>
          <option value="Règlement de stock.">Règlement de stock.</option>
          <option value="Correction d'une erreur de stock.">
            Correction d'une erreur de stock.
          </option>
          <option value="Saisie tardive des achats.">
            Saisie tardive des achats.
          </option>
        </select>
      </div>
    </div>
    <br />
    <div class="row justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-danger" @click="destroy(stock)">
          <i class="bi bi-check-square-fill"></i> Supprimer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      deletedReason: "",
    };
  },
  computed: {
    // ...mapGetters("item", {
    //   item: "getItem",
    // }),
    ...mapGetters("coldroom", {
      coldrooms: "getColdrooms",
    }),
    ...mapGetters("stock", {
      stock: "getStock",
    }),
  },
  methods: {
    async destroy(data) {
      let reference = data.reference;
      let reason = this.deletedReason;

      await this.$store.dispatch("stock/delete", {
        reference,
        reason,
      });
    },
  },
  beforeMount() {
    this.$store.dispatch("stock/show", this.$route.params.reference);
    this.$store.dispatch("coldroom/getAll");
  },
};
</script>
